define("discourse/plugins/discourse-staff-alias/discourse/initializers/discourse-staff-alias", ["exports", "@glimmer/tracking", "discourse/lib/plugin-api", "discourse/models/composer", "discourse-i18n"], function (_exports, _tracking, _pluginApi, _composer, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "discourse-staff-alias";
  function initialize(api) {
    const currentUser = api.getCurrentUser();
    if (currentUser?.can_act_as_staff_alias) {
      api.modifySelectKit("composer-actions").prependContent(component => {
        if (component.action === _composer.CREATE_TOPIC) {
          return [{
            name: (0, _discourseI18n.i18n)("composer.composer_actions.as_staff_alias.create_topic.label"),
            description: (0, _discourseI18n.i18n)("composer.composer_actions.as_staff_alias.create_topic.desc"),
            icon: "user-secret",
            id: "toggle_reply_as_staff_alias"
          }];
        }
      });
      api.modifySelectKit("composer-actions").appendContent(component => {
        if (component.topic?.details?.staff_alias_can_create_post && (component.action === _composer.REPLY || component.action === _composer.EDIT && component.get("post.post_type") !== component.get("site.post_types.whisper") && !component.get("post.is_staff_aliased"))) {
          return [{
            name: (0, _discourseI18n.i18n)(`composer.composer_actions.as_staff_alias.${component.action}.label`),
            description: (0, _discourseI18n.i18n)(`composer.composer_actions.as_staff_alias.${component.action}.desc`),
            icon: "user-secret",
            id: "toggle_reply_as_staff_alias"
          }];
        }
      });
      api.modifyClass("component:composer-presence-display", ComposerPresenceDisplayComponent => class extends ComposerPresenceDisplayComponent {
        get state() {
          const {
            isReplyAsStaffAlias
          } = this.args.model;
          if (isReplyAsStaffAlias) {
            return "whisper";
          }
          return super.state;
        }
      });
      api.modifyClass("component:composer-actions", {
        pluginId: PLUGIN_ID,
        toggleReplyAsStaffAliasSelected(options, model) {
          model.toggleProperty("replyAsStaffAlias");
          if (model.whisper) {
            model.set("whisper", false);
          }
        },
        toggleWhisperSelected(options, model) {
          this._super(...arguments);
          if (model.replyAsStaffAlias) {
            model.set("replyAsStaffAlias", false);
          }
        }
      });
      api.modifyClass("model:composer", Superclass => class extends Superclass {
        static #_ = (() => dt7948.g(this.prototype, "replyAsStaffAlias", [_tracking.tracked], function () {
          return false;
        }))();
        #replyAsStaffAlias = (() => (dt7948.i(this, "replyAsStaffAlias"), void 0))();
        static #_2 = (() => dt7948.g(this.prototype, "_originalUser", [_tracking.tracked]))();
        #_originalUser = (() => (dt7948.i(this, "_originalUser"), void 0))();
        get user() {
          if (this.isReplyAsStaffAlias && this.topic) {
            return this.get("topic.staff_alias_user");
          } else {
            return this._originalUser;
          }
        }
        set user(value) {
          this._originalUser = value;
        }
        get isReplyAsStaffAlias() {
          if (this.get("editingPost") && this.get("post.is_staff_aliased")) {
            return true;
          } else {
            return !this.get("whisper") && this.get("replyAsStaffAlias");
          }
        }
      });
      api.serializeOnCreate("as_staff_alias", "isReplyAsStaffAlias");
      api.serializeOnUpdate("as_staff_alias", "isReplyAsStaffAlias");
      api.serializeToTopic("as_staff_alias", "isReplyAsStaffAlias");
      api.includePostAttributes("aliased_username");
      api.includePostAttributes("is_staff_aliased");
      api.addPosterIcon((cfs, attrs) => {
        if (attrs.is_staff_aliased) {
          const props = {
            icon: "user-secret",
            className: "user-title"
          };
          if (attrs.aliased_username) {
            props.text = attrs.aliased_username;
            props.title = (0, _discourseI18n.i18n)("discourse_staff_alias.poster_icon_title", {
              username: attrs.aliased_username
            });
            props.url = `/u/${attrs.aliased_username}`;
          } else {
            props.text = (0, _discourseI18n.i18n)("discourse_staff_alias.aliased_user_deleted");
          }
          return props;
        }
      });
    }
  }
  var _default = _exports.default = {
    name: "discourse-staff-alias",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.staff_alias_enabled) {
        (0, _pluginApi.withPluginApi)("0.10.0", initialize);
      }
    }
  };
});